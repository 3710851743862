* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  height: 100vh;

}

.products {
  /* height: 100vh; */

}

.about_us {
  height: 100vh;
  padding-top: 5%;
  overflow-x: hidden;
}

.contact {
  /* height: 100vh; */
  
}

.faq {
  height: 100vh;
}

.how-to {
  height: 100vh;
}