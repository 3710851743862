* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#aboutus {
    /*  height: 100%; */
    /* background-color: black; */
    color: white;
    /* padding-top: 250px; */
}

.about_us {
    background-color: black;
    background-image: url("../images/frontview_cupcake.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 90%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.firstbox {

    border-style: solid;
    border-width: 0 2px 0 0;
    border-image: linear-gradient(to bottom, #FFFF 50%, #A8894A 40%)1;
    box-shadow: 15px 0 15px -15px rgb(0, 0, 0);
    width: 15%;
    height: 500px
}

.secondbox {
    color: #FFFF;
    font-size: 20px;
    width: 15%;
    height: 50vh;
    margin-left: 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;


}

.secondbox p {
    margin: 1vh 10px 1vh 10px;
}

@media screen and (max-width: 1760px) {
    .secondbox {
        width: 20%;
        height: auto;
        font-size: large;
        /* text-shadow: 1px 1px 2px black, 0 0 1em #fff, 0 0 0.2em #A8894A; */
    }
}

@media screen and (max-width: 1350px) {

    .about_us {
        /* justify-content: flex-start;
        align-items: flex-start; */
        /* background-size: contain; */
        background-position: bottom;
    }

    .secondbox {
        width: 25%;
    }


}

@media screen and (max-width: 1000px) {

    .secondbox {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .about_us {

        justify-content: flex-start;
        align-items: flex-start;
        background-size: contain;
        background-position: bottom;

        height: auto;
        padding-bottom: 30vh;




    }



    .firstbox {
        margin-left: 0px;
        height: 400px;

    }

    .secondbox {
        width: 60%;
        height: auto;
        
        /* text-shadow: 1px 1px 2px black, 0 0 1em #fff, 0 0 0.2em #A8894A; */
    }
}

@media screen and (max-width: 468px) {
    .firstbox {

        height: 500px;

    }

    .secondbox {
        font-size: 15px;

    }

}



