* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.navbar,
.extra-navbar {
    display: flex;
    list-style: none;
    justify-content: center;
    color: #FFFF;
    position: fixed;
    width: 100%;
    font-size: 1.5em;
    z-index: 3;
    transition: all .3s linear;
}

/* Navbar links hover effect */

.navbar-items>a {
    position: relative;
}

.navbar-items>a::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #A8894A;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out;
}

.navbar-items>a:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

/* --- */

.navbar-items,
.extra-navbar-items {
    margin: 40px 10px 0;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
}

.navbar-items .active {
    color: #A8894A;

}

.navbar-items a:hover,
.extra-navbar a:hover {
    color: #A8894A;
    /* border: 1px solid #A8894A;
    padding: 15px;
    margin: -10px;
    transition: all .1s linear;
    border-radius: 55%; */
}

.navbar-items a,
.extra-navbar a {
    transition: all .3s linear;
}

.extra-navbar a:visited,
.extra-navbar a:link {
    color: white;
    list-style: none;
    text-decoration: none;
}

.extra-navbar-items a:hover {
    color: #A8894A;
}

#edesm_logo {
    width: 220px;
    cursor: pointer;
    transition: all .3s linear;

}

.nav_scrolled {
    display: flex;
    list-style: none;
    justify-content: center;
    color: #FFFF;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 4;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.6);
    transition: all .3s linear;
}

.nav_scrolled>li {
    transform: scale(0.9);
    transition: all .3s linear;
}

.navbar>li {
    transition: all .3s linear;
}

#edesm_logo_scrolled {
    width: 200px;
    cursor: pointer;
    transition: all .3s linear;
}

/* #edesm_logo:hover {
    width: 240px;
} */

/* #edesm_logo_scrolled:hover {
    width: 210px;
} */

.lastletter {
    font-family: Parisienne;
    color: #A8894A;
}

.nav-toggle {
    display: none;
}

@media only screen and (max-width: 700px) {

    /* * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
       } */

    .navbar,
    .extra-navbar,
    .nav_scrolled {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
    }

    .nav_scrolled {
        background-image: none;
    }

    .nav_scrolled>li {
        transform: none;
    }

    .navbar>img,
    .nav_scrolled>img {
        position: absolute;
        top: 5%;
        margin: 0 auto 0 auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    #edesm_logo,
    #edesm_logo_scrolled {
        width: 300px;
    }

    .extra-navbar {
        display: none;
    }


}

@media only screen and (max-width: 468px) {
    #edesm_logo {
        width: 150px;

    }

    #edesm_logo_scrolled {
        width: 150px;
    }
}