/* .galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    margin: 0 auto;
}
.galleryWrap .gallery_container {
    width: 150px;
    cursor: pointer;
}
.galleryWrap .gallery_container img {
    max-width: 100%;
}
.galleryWrap .gallery_container img:hover {
    transform: scale(1.02);
} */

.galleryWrap {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 2fr);
    grid-auto-rows: 10.5vw 10.5vw;
    grid-gap: 25px;
    margin: 2em 0 2em 0;

}

.gallery_item {
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 20px;

}

.gallery_item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.gallery_item .image img {
    object-fit: cover;
    /* object-position: 50% 50%; */
    cursor: pointer;
    transition: .5s ease-in-out;
}

.gallery_item:hover .image img {
    transform: scale(1.5);
}


/* PRODUCT MODAL ANIMATED TEXT
bug with positioning of text, removing .gallery_item > position relative fixes it */

.gallery_item .text {
    opacity: 0;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 4;
    transition: .3s ease-in-out;
    -webkit-backdrop-filter: blur(5px) saturate(1.8);
    backdrop-filter: blur(5px) saturate(1.8);
    border-radius: 15px;
    width: 100%;
    color: black;
}

.gallery_item:hover .text {
    opacity: 1;
    font-size: 23px;
    animation: move_from_left .3s linear;
    /* padding: 5px; */
    color: black;
    width: 100%;
    transition: .3s ease-in-out;

}


@keyframes move_from_left {
    0% {
        left: 10%;
    }

    50% {
        left: 35%;
    }

    100% {
        left: 50%;
    }
}

.image {
    border-radius: 10px;
    border: 3px solid #A8894A;
}

.product_modal_container {
    overflow: auto;
    margin: 1vw 10vw 1vw 10vw;
    padding: 1vw;
    width: 60%;
    height: 75vh;
    border: 3px solid #A8894A;
    border-radius: 15px;
    background-image: url("../../images/gold3.jpg");
    background-position: bottom;
    box-shadow: 0px 32px 15px 19px rgba(0, 0, 0, 0.9);
    /* z-index: 1; */

}

.title_and_close {
    display: flex;
    justify-content: space-between;
}

.title_and_close>h3 {
    font-family: 'Times New Roman', Times, serif;
    font-size: larger;
    display: flex;
    align-items: center;
    color: #A8894A;
    border: 3px solid #A8894A;
    border-radius: 15px;
    padding: 1%;
    /* margin-top: 4%; */
}

.gallery_modal_close {
    margin-top: 1vh;
    font-size: 28px;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 999;
}

.gallery_modal_close h3 {
    background-color: blue;
}

.gallery_modal_close:hover {
    opacity: 1;
}

/* SCROLLBAR */


::-webkit-scrollbar {
    width: 1.5em;
    background-color: black;
}

.product_modal_container::-webkit-scrollbar {
    width: 1.5em;
    left: -10em;
    background-color: black;
    border-radius: 100vh;
}

::-webkit-scrollbar-track {
    background: #A8894A;
    border-radius: 15px;
    /* margin-block: .5em; */
}

.product_modal_container::-webkit-scrollbar-track {
    margin-block: 0;
}

::-webkit-scrollbar-thumb {
    background: hsl(40, 39%, 22%);
    border: .25em solid #A8894A;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    background: hsl(41, 38%, 13%);
}


/* SLIDERWRAP */
.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
}

.btnNext:hover,
.btnClose:hover,
.btnPrev:hover {
    opacity: 1;
}

.sliderWrap .btnClose {
    top: 40px;
    right: 40px;
    font-size: 28px;
}

.sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    font-size: 28px;
}

.sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    font-size: 28px;
}

.fullScreenImage {
    /* width: calc(100% - 40px);
    height: calc(100% -40px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.fullScreenImage img {
    height: 85vh;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
}

@media screen and (max-width: 1180px) {
    .fullScreenImage img {
        height: 100%;
        width: 100%;
        
    }
    
    .gallery_item .modalgalleryText {
        display: none;
    }

}


@media screen and (max-width: 768px) {
    .fullScreenImage img {
        height: 100%;
        width: 100%;
    }

    .galleryWrap {
        grid-template-columns: repeat(2, 4fr);

        grid-auto-rows: 20.5vw 20.5vw;
        margin: 0;
        padding: 0;
        grid-gap: 10px;
        border-radius: 0;
        border: 0;
    }

    .product_modal_container {
        width: 100%;
        height: 80vh;
        
    }

    .image {
        border-radius: 0;
        border: 0;
    }

    .title_and_close {
        margin: 5% 0 5% 0;
    }

    
    
}