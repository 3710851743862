* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

p > a {
    color: #A8894A;
}

.how-to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url("../images/howto_background.jpg");
    background-size: 100%;
    background-position: center;
    color: #FFFF;
}
.howto_text {
    padding-top: 20vh;
    padding-left: 10vh;
    max-width: 60vw;
}
.howto_text > h1,
.howto_text > h2,
.howto_text > h3 {
    margin-bottom: 0.5%;
    color: #A8894A;;
}
.howto_text > h3 {
    margin-top: 2%;
    
}
.back_button_howto {
    margin: 2% 0 0 0;
    padding: 0.4% 0 0.4% 0;
    cursor: pointer;
    border: 2px solid #46381e;
    background-color: #836a39;
    border-radius: 10px;
    width: 13%;
    text-align: center;
    transition: all 0.2s linear;
    
}
.back_button_howto:hover {
    border: 2px solid #FFFF;
    transition: all 0.2s linear;
    box-shadow: 0 0 5px 5px #A8894A !important;
}

@media screen and (max-width: 768px) { 

    .how-to {
        background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url("../images/howto_background.jpg");
        background-size: cover;
    }

    .howto_text {
        padding-top: 20%;
        padding: 10%;
        text-shadow: 0px 1px 2px #46381e;
        max-width: 100%;
    }

    .back_button_howto {
        width: 50%;
    }
}
