* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.main-mail-icon {
    cursor: pointer;
    margin: 0 0.3vw 0 0;

}

.order-now {
    font-size: x-large;
}

.main-email {
    text-decoration: none;
    color: #A8894A;
    font-size: x-large;

}

/* email hover effect */

.main-email {
    position: relative;

}

.main-email::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #A8894A;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out;

}

.main-email:hover:before {
    visibility: visible;
    transform: scaleX(1);

}

/* ///////// */

.products {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 5%;
    background-color: black;


}

.productcard {
    width: 460px;
    height: 170px;
    /* width: 130%;
    height: 170px; */
    color: white;
    border: 1px solid #A8894A;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    background-position: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 5rem 0 0 10rem;
    transition: all .3s linear;
    cursor: pointer;



}


.productcard:hover {
    color: #A8894A;
    transform: scale(1.09);
    transition: all .3s linear;
}

.product_category_images {
    width: 600px;
    height: 200px;
    object-fit: cover;
    object-position: bottom;

}

.products_container {
    background-color: black;
    color: white;
    padding-bottom: 20vh;
    background-size: 100%;


}

.products_container_ifModalIsOpen {
    background-color: black;
    color: white;
    padding-bottom: 20vh;
    background-size: 100%;
}

.product_categry_container {
    display: flex;
    justify-content: center;
}

.between_main_and_products_container {
    padding-top: 5%;
    display: flex;
    justify-content: center;
    gap: 5%;
}


.card_content {
    /* padding: 40px 0 0 20px; */
    /* height: 100%; */
    font-size: 20px;
    text-align: center;

    /* text-shadow: 3px 0px 7px rgba(81,67,21,0.8), 
	  -3px 0px 7px rgba(81,67,21,0.8), 
	  0px 4px 7px rgba(81,67,21,0.8); */
}

/* PRODUCT MODAL */

.product_modal {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.product_overlay {

    display: flex;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100%;
    margin-top: 50px;

}

.product_modal_container {
    width: 80vw;
    height: 80vh;

}

@media screen and (max-width: 1465px) {
    .card_content {
        text-align: center;
        display: flex;
        align-items: center;
        /* padding: 20px; */
        height: 100%;
    }

    .products_container {
        background-color: black;
        color: white;
        padding-bottom: 0;
        background-size: 100%;


    }

    /* SCROLL ANIMATION */


    /* * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
    } */

    .products {
        display: grid;


    }

    .productcard {
        padding: 0;
        width: 300px;
        height: 100px;
        color: #FFFF;
        border: 1px solid #A8894A;
        background-position: center;
        padding: 0px;
        overflow: hidden;


    }

    .card_content {
        padding: 0;
        height: 100%;
        background: none;
        display: flex;
        justify-content: center;
    }

}

@media screen and (max-width: 997px) {
    .products {
        /* display: grid;
        grid-template-columns: repeat(2, 1fr); */
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 5%;
        background-color: black;


    }

    .product_overlay {
        margin-top: 30px;
    }

}


@media screen and (max-width: 768px) {

    .products_container {
        background-color: black;
        color: white;
        padding-bottom: 20vh;
        background-size: 100%;


    }

    .product_overlay {
        margin-top: 0px;
        padding-bottom: 0px;
    }


    .order-now {
        font-size: smaller;
    }

    .main-email {
        font-size: small;
    }


    .between_main_and_products_container {
        padding-bottom: 15%;
    }

    .card_content {
        text-align: center;
        display: flex;
        align-items: center;
        /* padding: 20px; */
        height: 50px;
    }



    /* SCROLL ANIMATION */


    /* * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
    } */

    .products {
        display: flex;
        flex-direction: column;

    }

    .productcard {
        padding: 0;
        width: 300px;
        height: 50px;
        color: #FFFF;
        border: 1px solid #A8894A;
        background-position: center;
        padding: 0px;
        overflow: hidden;


    }

    .card_content {
        padding: 0;
        height: 100%;
        background: none;
        display: flex;
        justify-content: center;
    }

}

@media screen and (max-width: 468px) {
    .productcard {
        padding: 0;
        width: 250px;
        height: 50px;
        color: #FFFF;
        border: 1px solid #A8894A;
        background-position: center;
        padding: 0px;
        overflow: hidden;


    }

    .between_main_and_products_container {
        padding-top: 45px;
        display: flex;
        justify-content: center;
        gap: 5%;
        
    }
    

}