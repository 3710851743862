* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#contact {
    background-image: url("../images/dark-bronze-stone-background.jpg");
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;




}

.contact h1 {
    text-align: center;
    color: #ffff;
}


.linear_gradient_contact_page {
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.9),
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.05),
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0) 140%);
}

.contact_box_left {
    grid-area: form;
}


.contact_box_second {
    grid-area: contact;
}

.contact_box_map {
    grid-area: map;
}

.inputBox span {
    color: #ffff;
}

.row50 .inputBox,
.row100 {
    margin: 1%;
}

.inputBox>textarea {
    font-size: 28px;
}

.contact_box_left h2 {
    display: block;
    font-size: 24px;
    margin: 3vh 0 3vh 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    font-weight: 100;
}

.contact_boxes {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas:
        "form contact"
        "form map";
    grid-gap: 50px;
    padding: 3% 10% 2% 10%;


}


.contact_box_left {
    background-image: url("../images/gold4.jpg");
    background-size: cover;
    background-position: center;
    padding: 3%;
    box-shadow: 0px 1px 18px 5px #000000;
    
}

/* INFOBOX */


.contact_box_second h3 {
    display: block;
    font-size: 24px;
    margin: 3vh 0 3vh 0;
    text-align: center;
    font-weight: 100;
    
}

.contact_box_second {
    color: #ffff;
    background-image: url("../images/gold5.jpg");
    background-size: cover;
    font-size: 20px;
    padding: 3vh 5vh 3vh 5vh;
    box-shadow: 0px 1px 18px 5px #000000;
    
}


.infobox div {
    display: flex;
    margin-bottom: 10px;
}

.infobox div span {
    min-width: 40px;
    height: 40px;
    color: #ffff;
    background: #A8894A;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    border-radius: 50%;
    margin-right: 15px;
    transition: all .3s ease-in-out;
    
    
}

.infobox div span:hover {
    transform: scale(1.2);
    transition: all .3s ease-in-out;
}

.infobox div p {
    color: #ffff;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    
}

.infobox div a {
    color: #ffff;
    text-decoration: none;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    position: relative;
}

.infobox div a::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #A8894A;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out;
}

.infobox div a:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.social_media_links {
    margin-top: 20px;
    display: flex;
}

.social_media_links li {
    list-style: none;
    margin-right: 30px;
}

.social_media_links li a {
    color: rgb(185, 185, 185);
    font-size: 2em;
    
}

.social_media_links li {
    display: inline;
}

.social_media_links li a:hover {
    color: #ffff;
}

.contact_box_map {
    /* border: 3px solid #A8894A; */
    
    /* margin: 0% 5% 0 5%; */
    /* border-radius: 10px; */
    color: #ffff;
    box-shadow: 0px 1px 18px 5px #000000;
    overflow: hidden;
    
    
    
}

#gmap_canvas {
    /* border-radius: 15px; */
    height: 100%;
    width: 100%;
    
    
    
}

/* CONTACT FORM */
.contact_form {
    position: relative;
    width: 100%;
}

.contact_form .row50 {
    display: flex;
    gap: 20px;
}

.inputBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 50%;
    
}

.contact_form .row100 .inputBox {
    width: 100%;
}


.inputBox span {
    margin: 10px 0 5px 0;
    font-weight: 500;
}

.inputBox input {
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border: 1px solid #836A39;
}

.inputBox textarea {
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 24px;
    outline: none;
    border: 1px solid #836A39;
    resize: none;
    min-height: 220px;
    margin-bottom: 10px;
}

.contact_form_submitbutton {
    background-color: #836A39;
    color: #FFFF;
    border: 2px solid #2e2513;
    border-radius: 5px;
    transition: all 0.2s linear;
    width: 150px;
    cursor: pointer;
    
}

.contact_form_submitbutton:hover {
    border: 1px solid #FFFF;
    box-shadow: 0 0 5px 5px #A8894A !important;
    transition: all 0.2s linear;
}



/* ////FOOTER/// */


.footer_box {
    margin-top: auto;
    background-color: #3d2f17;
    text-align: center;
    /* border-top: 1px solid #A8894A; */
    
    
}

.footer_line {
    background-color: #A8894A;
    width: 80%;
    height: 3px;
    box-shadow: 0px 1px 18px 2px #000000;
    border: 1px solid #524221;
    
}

.footer_line_box {
    display: flex;
    justify-content: center;
    background-color: #3d2f17;
    padding-bottom: 1%;
    
}



.footer_links {
    /* margin-top: 5vh; */
    /* padding-top: 1vh; */
    display: flex;
    justify-content: center;
    font-size: 14px;
    
}

.footer_faq_link,
.footer_howto_link,
.footer_aszf_link,
.footer_partners_link {
    width: 360px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #836A39;
    border: 1px solid #524221; */
    margin: 10px 10px 10px 10px;
    border-radius: 15px;
    font-family: 'Times New Roman', Times, serif;
    color: #FFFF;
    font-size: 130%;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s linear;
    /* box-shadow: 0px 1px 18px 2px #000000; */
}


.footer_faq_link:hover,
.footer_howto_link:hover,
.footer_aszf_link:hover,
.footer_partners_link:hover {
    /* border: 1px solid #FFFF; */
    box-shadow: 0 0 5px 5px #A8894A !important;
    transition: all 0.2s linear;
}

.tothetopbutton {
    font-size: 250%;
    color: #836A39;
    cursor: pointer;
    margin: 0 1vw 0 1vw;
    transition: all 0.2s linear;
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 51%, #836A39 53%);
    border-radius: 50%;
    box-shadow: 0px 1px 18px 2px #000000;
}


.tothetopbutton:hover {
    color: #FFFF;
    box-shadow: 0 0 5px 5px #A8894A !important;
    border-radius: 100%;
    transition: all 0.2s linear;
    background: #524221;
    border-radius: 25px;
    overflow: hidden;
    
}

.toTheTopWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.creator_contact {
    margin: 10px 0 20px 0;
    display: flex;
    justify-content: space-around;
}
.creator_contact span, .creator_contact a {
    font-family: 'Times New Roman', Times, serif;
    color: #FFFF;
    font-size: 18px;
    text-decoration: none;
}

.creator_contact a {
    position: relative;
}

.creator_contact a::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: black;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out;
}

.creator_contact a:hover:before {
    visibility: visible;
    transform: scaleX(1);
}


@media screen and (max-width: 1275px) {
    #contact {
        height: auto;
    }
    
    .contact_box_left,
    .contact_box_second,
    .contact_box_map {
        margin: 0;
        padding: 0;
    }
    
    .contact_boxes {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        "form"
        "contact"
        "map";
        grid-gap: 50px;
        padding: 10px 20px 40px 20px;
        
    }
    
    .contact_box_second {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .social_media_links {
        justify-content: center;
        margin-bottom: 15px;
    }
    
    .footer_faq_link,
    .footer_howto_link,
    .footer_aszf_link,
    .footer_partners_link {
        max-width: 260px;
        max-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 10px 10px;
        border-radius: 15px;
        font-family: 'Times New Roman', Times, serif;
        color: #FFFF;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s linear;
    }
    
    /* .contact_form .row50 {
        flex-direction: column;
    } */
}

@media screen and (max-height: 1000px) {
    #contact {
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    
    #contact {
        height: auto;
    }
    
    .contact_box_left,
    .contact_box_second,
    .contact_box_map {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    
    .contact_boxes {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        "form"
        "contact"
        "map";
        grid-gap: 50px;
        padding: 10px 20px 40px 20px;
        
    }
    
    
    .contact_form .row50 {
        flex-direction: column;
    }
    
    
    
    .inputBox {
        width: 100%;
        
    }
    
    .inputBox input {
        width: 95%;
        margin: 0 5px 0 5px;
    }
    
    .inputBox span {
        margin-left: 8px;
    }
    
    .inputBox textarea {
        width: 97%;
        
    }
    
    .row100 .inputBox {
        align-items: center;
        
    }
    
    .row100 .inputBox span {
        margin-right: auto;
    }
    
    .row100 .inputBox .contact_form_submitbutton {
        width: 150px;
    }
    
    .contact_box_second {
        font-size: 16px;
        padding: 0px 10px 15px 15px;
    }
    
    
    /* FOOTER */
    
    .footer_links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px 0 65px 0;

    }


    .toTheTopWrapper {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
    }

    .toTheTopWrapper {
        position: absolute;
    }

    .aos-animate {

        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;

        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;

        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}