* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.faq {
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), url("../images/cupcake.jpg");
    background-size: 100%;
    background-position: center;
    color: #FFFF;
}

.faq_text {
    padding-top: 20vh;
    padding-left: 10vh;
    max-width: 40vw;
}

.faq_text>h1,
.faq_text>h2,
.faq_text>h3 {
    margin-bottom: 0.5%;
    color: #A8894A;
    ;
}

.faq_text>h3 {
    margin-top: 4%;

}

.back_button_faq {
    margin: 3% 0 0 0;
    padding: 0.4% 0 0.4% 0;
    cursor: pointer;
    border: 2px solid #46381e;
    background-color: #836a39;
    border-radius: 10px;
    width: 20%;
    text-align: center;
    transition: all 0.2s linear;
    
}

.back_button_faq:hover {
    border: 2px solid #FFFF;
    transition: all 0.2s linear;
    box-shadow: 0 0 5px 5px #A8894A !important;
}

@media screen and (max-width: 768px) {

    .faq {
        background-image: linear-gradient(rgba(0, 0, 0, 0.627), rgba(0, 0, 0, 0.9)), url("../images/cupcake.jpg");
        background-size: cover;
    }

    .faq_text {
        padding-top: 20%;
        padding: 10%;
        text-shadow: 0px 1px 2px #46381e;
        max-width: 100%;
    }

    .back_button_faq {
        width: 50%;
    }
}