* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    background-color: #000000;
    text-align: center;
    position: relative;
}

/* .cake_background {
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

} */

.image_slider {
    /* width: 70%; */
    height: 100%;
    /* position: absolute; */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 15%;

    /*  background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}

.slideshow_overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

}

.main-text-top {
    color: white;
    font-family: 'Cormorant', serif;
}

.main-text-bottom {
    color: #A8894C;
    font-family: 'Cormorant', serif;
}

.main-text-box {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-size: 150%;
    height: 15%;
    z-index: 3;

}



.golden_line {
    position: absolute;
    width: 7%;
    height: 1px;
    margin: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    z-index: 2;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(168, 137, 74, 1) 50%);
    transform: rotate(90deg);

}


/* SIDE NEWS SEGMENT */

.side_pop_up {
    position: fixed;
    width: 650px;
    height: 350px;
    opacity: 0.75;
    border-top-right-radius: 10px;
    bottom: 0;
    left: 0;
    box-shadow: 17px -4px 27px 2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 17px -4px 27px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 17px -4px 27px 2px rgba(0, 0, 0, 0.75);
    background-image: url("../images/orszagtorta_popup_main.jpg");
    background-size: cover;
    transform: translateX(-100%);
    transition: 0.5s ease-in;
    z-index: 4;


}

.side_pop_up_active {
    position: absolute;
    transform: translateX(0);
    transition: 0.5s ease-in;
}

.closeNewsButton {
    position: absolute;
    color: white;
    right: 0;
    margin: 5px;
    opacity: 1;
    cursor: pointer;
    font-size: large;
}


@media only screen and (max-width: 700px) {

    /* .image_slider {
        display: none;
    } */
    .side_pop_up {
        width: 250px;
        height: 130px;
    }

    .slideshow_overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);

    }

    .main-text-box {
        display: none;
    }


}